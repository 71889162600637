import PropTypes from 'prop-types';
import { PureComponent } from 'react';
/** @namespace Bodypwa/Component/CookieScriptPage/Container */
export class CookieScriptPageContainer extends PureComponent {
    static propTypes = {
        elements: PropTypes.shape({
            attribs: PropTypes.shape({
                src: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    };

    componentDidMount() {
        const { elements: { attribs: { src, type } } } = this.props;
        const script = document.createElement('script');
        script.type = type;
        script.setAttribute('data-cookiescriptreport', 'report');
        script.src = src;

        document.querySelector('[data-cookiescript="element"]').appendChild(script);
    }

    render() {
        return <div data-cookiescript="element" />;
    }
}

export default CookieScriptPageContainer;
